import type { FC } from "react";
import React from "react";
import type { TrackEvent } from "src/state/Track/TrackCubit";
import { tracker } from "src/state/state";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";
import MultiStepPharmacyInsuranceForm from "../../MultiStepPharmacyInsuranceForm/MultiStepPharmacyInsuranceForm";
import translate from "src/lib/translate";

const EnterPharmacyInsurance: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();
  const [modalRef, setModalRef] =
    React.useState<React.RefObject<HTMLIonModalElement> | null>(null);

  const onClose = () => {
    void modalRef?.current?.dismiss();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const handleSaved = () => {
    tracker.track("Pharmacy Insurance Card Added/Updated" as TrackEvent);
    AppQueryPopupsController.closePopup();
  };

  return (
    <InDialog
      popup
      title={translate("pharmacyInsuranceCard")}
      onClose={onClose}
      setRef={setModalRef}
      returnUrl={returnUrl}
    >
      <MultiStepPharmacyInsuranceForm
        onInsuranceSaved={handleSaved}
        onCanceled={onClose}
      />
    </InDialog>
  );
};

export default EnterPharmacyInsurance;
